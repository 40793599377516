export const FALLBACK_IMAGE = 'https://bit.ly/broken-link';
export const LOGO = '/app-icon.png';
export const BANNER = '/banner.jpeg';
export const ONBOARDING_ICON = '/onboardingIcon.png';
export const ONBOARDING_ICON_2 = '/get-started.jpg';
export const PROFILE_IMAGE = '/profile-avatar.png';
export const ONBOARDING_PROGILE_BG = '/profile-bg.png';
export const COMPANY_TOP_BANNER = '/dashboard-bg.png';
export const COMPANY_LOGO = '/icon-1.png';
export const DEFAULT_PROFILE_IMAGE = '/user-default-icon.png';
export const PROFILE_BANNER_IMAGE = '/banner-pattern.jpeg';
export const EMPTY_DASHBOARD = '/empty-dashboard.jpeg';
export const EMPTY_INTERNSHIP = '/empty-internship.png';
export const UPLOAD_ICON = '/upload-icon.png';
export const COMPANY_DETAILS_BG = '/company-details-bg.jpg';
export const QR_LOGO = '/sample-qr.png';
export const PDF_ICON = '/pdf-icon.webp';
export const PDF_DOWNLOAD_ICON = '/pdf-download.png';
export const DOC_DOWNLOAD_ICON = '/doc-download.png';
export const ARROW_RIGHT = 'assets/arrow-right.png';
export const ARROW_LEFT = '/arrow-left.png';
export const UNIVERSITY_ICON = '/university-icon.png';
export const COMPANY_EMPTY_ICON = '/images/company-empty-icon.png';
export const DEFAULT_CAREER_OFFICE_TOPIC_BANNER =
  '/images/career-office-topic-banner.jpg';
