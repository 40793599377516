import {
  BusinessCenterOutlined,
  CalendarToday,
  FavoriteBorderOutlined,
  GridView,
  HelpOutlineOutlined,
  LogoutOutlined,
  MonitorOutlined,
  NotificationsNoneOutlined,
  Person2Outlined,
  SettingsOutlined,
} from '@mui/icons-material';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';

import {
  CALENDAR,
  CAREER_OFFICE_COUNSELLORS,
  CAREER_OFFICE_UNIV_PROFILE,
  CHAT,
  DASHBOARD,
  FAVORITES,
  HELP_AND_FEEDBACK,
  INTERNSHIP_ACCEPTED,
  INTERNSHIP_APPLICATION,
  INTERNSHIP_INTERVIEW,
  INTERNSHIP_SEARCH,
  NOTIFICATION,
  PROFILE,
  SETTINGS,
  SIGNOUT,
} from './routes';

export const APP_NAME = 'INTERNSHIP_APP';

export const APP_BAR_HEIGHT = 50;
export const APP_SIDEBAR_WIDTH = 230;
export const DRAWER_WIDTH = 230;
export const DRAWER_WIDTH_MINI = 90;
export const DEFAULT_PAGINATION_PAGE_NO = 0;
export const DEFAULT_PAGINATION_COUNT = 10;
export const DEFAULT_SORTING = 'ASC';
export const DEFAULT_PAGINATION = {
  data: [],
  page: DEFAULT_PAGINATION_PAGE_NO,
  count: DEFAULT_PAGINATION_COUNT,
  total: 0,
  next: false,
  previous: false,
};
export const EMPTY_FIELD_INDICATOR = '-';
export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please refresh and try again.';
export const DEFAULT_DELETING_MESSAGE = 'Deleting...';
export const DEFAULT_DELETED_MESSAGE = 'Successfully deleted';

export const DRAWER_MENU = [
  {
    id: 1,
    title: 'Dashboard',
    url: DASHBOARD,
    icon: GridView,
  },
  {
    id: 2,
    title: 'Career Office',
    url: CAREER_OFFICE_COUNSELLORS,
    icon: DrawOutlinedIcon,
    items: [
      {
        id: 1.1,
        title: 'Counsellors',
        url: CAREER_OFFICE_COUNSELLORS,
      },
      {
        id: 1.2,
        title: 'University  Profile',
        url: CAREER_OFFICE_UNIV_PROFILE,
      },
    ],
  },
  {
    id: 3,
    title: 'My Internship',
    url: INTERNSHIP_SEARCH,
    icon: BusinessCenterOutlined,
    items: [
      {
        id: 1,
        title: 'Public Internship',
        url: INTERNSHIP_SEARCH,
      },
      {
        id: 2,
        title: 'University Internship',
        url: INTERNSHIP_APPLICATION,
      },
      {
        id: 3,
        title: 'Interview',
        url: INTERNSHIP_INTERVIEW,
      },
      {
        id: 4,
        title: 'Accepted Internship',
        url: INTERNSHIP_ACCEPTED,
      },
    ],
  },
  {
    id: 4,
    title: 'Chat',
    url: CHAT,
    icon: MonitorOutlined,
  },
  {
    id: 5,
    title: 'Calendar',
    url: CALENDAR,
    icon: CalendarToday,
  },
  {
    id: 6,
    title: 'Profile',
    url: PROFILE,
    icon: Person2Outlined,
  },
  {
    id: 7,
    title: 'Favorites',
    url: FAVORITES,
    icon: FavoriteBorderOutlined,
  },
  {
    id: 8,
    title: 'Notification',
    url: NOTIFICATION,
    icon: NotificationsNoneOutlined,
  },
  {
    id: 9,
    title: 'Help and Feedback',
    url: HELP_AND_FEEDBACK,
    icon: HelpOutlineOutlined,
  },
  {
    id: 10,
    title: 'Settings / 設定',
    url: SETTINGS,
    icon: SettingsOutlined,
  },
  {
    id: 11,
    title: 'Sign Out',
    url: SIGNOUT,
    icon: LogoutOutlined,
    type: 'dialog',
  },
];

export const tableHeaderStyles = {
  fontSize: 10,
  fontFamily: 'Poppins',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontWeight: 500,
  flex: 1,
};

export const tableTextStyles = {
  fontFamily: 'Poppins',
  fontSize: 10,
  lineHeight: '15px',
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 1,
};

export const paginationState = {
  previous: null,
  next: null,
  noOfPageItems: DEFAULT_PAGINATION_COUNT,
  page: DEFAULT_PAGINATION_PAGE_NO,
  total: DEFAULT_PAGINATION_COUNT * (DEFAULT_PAGINATION_PAGE_NO + 1),
  pageCount: DEFAULT_PAGINATION_PAGE_NO + 1,
  hasMore: true,
  loading: true,
  data: [],
};

export const DEFAULT_LANGUAGE = 'ja';

export const LANGUAGES = [
  {
    text: 'Japanese',
    value: 'ja',
  },
  {
    text: 'English',
    value: 'en',
  },
];
export const INTERVIEW_INVITATION_STATUS_ACCEPTED = 0;
export const INTERVIEW_INVITATION_STATUS_DENY = 1;
export const INTERVIEW_INVITATION_STATUS_RESCHEDULE = 2;

export const INTERVIEW_INVITATION_STATUS = {
  [INTERVIEW_INVITATION_STATUS_ACCEPTED]: 'Accepted',
  [INTERVIEW_INVITATION_STATUS_DENY]: 'Deny',
  [INTERVIEW_INVITATION_STATUS_RESCHEDULE]: 'Reschedule',
};

export const CARD_VIEW = 0;
export const LIST_VIEW = 1;
export const VIEW_TYPES_MAP = {
  [CARD_VIEW]: 'Card View',
  [LIST_VIEW]: 'List View',
};
export const VIEW_TYPES = Object.values(VIEW_TYPES_MAP);
export const DEFAULT_VIEW_TYPE = LIST_VIEW;

export const INTERVIEW_DENY = {
  0: 'Decide to get another job',
  1: 'conditions do not match',
  2: 'the job discribtion changed"',
  3: 'family will',
  4: "Interviewer's attitude was not good",
  5: 'HR response was not good',
  6: 'Others',
};

export const INTERVIEW_DENY_OPTIONS = Object.keys(INTERVIEW_DENY).map(o => ({
  value: o,
  text: INTERVIEW_DENY[o],
}));

export const MONTHS_DROPDOWN = [
  {
    name: 'Jan',
    value: 1,
  },
  {
    name: 'Feb',
    value: 2,
  },
  {
    name: 'Mar',
    value: 3,
  },
  {
    name: 'April',
    value: 4,
  },
  {
    name: 'May',
    value: 5,
  },
  {
    name: 'June',
    value: 6,
  },
  {
    name: 'July',
    value: 7,
  },
  {
    name: 'Aug',
    value: 8,
  },
  {
    name: 'Sept',
    value: 9,
  },
  {
    name: 'Oct',
    value: 10,
  },
  {
    name: 'Nov',
    value: 11,
  },
  {
    name: 'Dec',
    value: 12,
  },
];
