import { Avatar, Box, Chip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const CompanyAvatar = ({
  data,
  company,
  sx,
  backToUrl,
  goToUrl,
  children,
}) => {
  const { t } = useTranslation('pages');
  const navigate = useNavigate();

  const onViewCompanyDetails = () => {
    navigate(goToUrl, {
      state: { data: company, backToUrl: backToUrl },
    });
  };

  return (
    <Box display="flex" alignItems="center">
      <Avatar
        variant="rounded"
        src={company?.logoUrl}
        onMouseDown={onViewCompanyDetails}
        sx={{
          width: '3rem',
          height: '3rem',
          '&:hover': {
            cursor: 'pointer',
            boxShadow: 4,
            elevation: 4,
          },
          ...sx,
        }}
        alt={t('Company Logo')}
        loading="lazy"
      />
      <Box ml={2}>
        <Typography
          variant="h6"
          sx={{
            '&:hover': {
              cursor: 'pointer',
              color: 'primary.main',
            },
          }}
          gutterBottom
          onMouseDown={onViewCompanyDetails}
        >
          {company?.name}
        </Typography>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Typography
            sx={{
              '&:hover': {
                cursor: 'pointer',
                color: 'primary.main',
              },
              color: '#27187E',
            }}
          >
            {data?.title}
          </Typography>
          <Chip
            label={'Remote'}
            sx={{ fontSize: 6, height: 12, width: 55, color: '#fff' }}
          />
        </Box>
      </Box>
      <Box sx={{ marginLeft: 'auto' }}>{children}</Box>
    </Box>
  );
};
